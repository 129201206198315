/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "layouts/main";
import { $all } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";
import 'components/Breadcrumbs';
import { Title } from "components/Title";
import { Widget } from "components/Widget";
import 'components/Bg';
import 'components/SectionA';
import 'components/SectionB';
import 'components/SectionC';
import 'components/SectionD';
import 'components/ImagesSingle';
import 'components/ImagesTwin';
import 'components/ImagesSlider';
import slider from "components/Slider";
import { CardProduct } from "components/Card/Product";
import { CardProject } from "components/Card/Project";
import { CardNews } from "components/Card/News";
import "components/ActionBar";
import "./index.scss";

console.log("Route home/index.js mounted.");

Title();
Widget();

const glideEl = $all(".ImagesSlider:");
if (glideEl.length > 0) {
    for (let s of globalData.sliders) {
        if (s.name == 'images-slider') {
            slider('.Slider:slider__'+s.id);
        }
    }
}

CardProduct();
CardProject();
CardNews();
