import { $all, on } from "@olmokit/dom";
import "./Project.scss";
// import { $ } from "@acanto/core-dom";

/**
 * Component: CardProject
 */
export function CardProject() {
  console.log("CardProject mounted.");

  const cardImgs = $all(".CardProject:img");
  const cardProps = $all(".CardProject:typology");
  
  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }
  if (cardProps) {
    for(let i=0; i < cardProps.length; i++){
      on(cardProps[i], "click", () => {
        let dest = cardProps[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }

}
