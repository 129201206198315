import { $, on, addClass, removeClass } from "@olmokit/dom";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: Widget
 */

const widget = $('.Widget:');
const closeW = $(".Widget:close");

export function Widget() {
  console.log("Widget mounted.");

  openWidget();

  on(closeW, 'click', (e) => {
    closeWidget();
  });

}

// Widget functions
function openWidget() {
  if (!sessionStorage.hasOwnProperty("alreadyopened")) {
    setTimeout(() => {
      addClass(widget, "is-active");
    },5500)
  }
}
function closeWidget() {
  sessionStorage.setItem("alreadyopened", "true");
  removeClass(widget, "is-active");
}
