import { $, $all, on, forEach, addClass } from "@olmokit/dom";
import { breakpoints, gridGutter } from "@olmokit/core/scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  // LazyLoad,
  // CrossFade,
  // AutoHeight,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import "@olmokit/core/glide/arrows";
import "./index.scss";

/**
 * Component: Slider
 */
export default function Slider(rootSelector) {
  console.log("Slider mounted.");

  let slidelength = $(rootSelector).getAttribute('data-slide');
  // console.log(slidelength);
  if (parseInt(slidelength) == 1 ) {
    notSlider(rootSelector);
  } else if (parseInt(slidelength) >= 2 ) {
    startSlider(rootSelector);
  }

}

function notSlider(element) {
  // console.log('not slider');
  const root = $(element);
  addClass(root, 'not-slider');
}

function startSlider(element) {
  const $container = `${element ? element + " " : ""}`;
  // console.log(breakpoints.lg);
  const slider = glide(
    $container,
    {
      loop: true,
      autoplay: 4500,
      hoverpause: true,
      animationDuration: 1000,
      killWhen: { above: 99999 },
      bound: true,
      // autoHeight: true,
      breakpoints: {
        [breakpoints.over]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 21, after: gridGutter * 21 },
        },
        [breakpoints.fullhd]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 14, after: gridGutter * 14 },
        },
        [1680]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 8, after: gridGutter * 8 },
        },
        [breakpoints.xxl]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 4.25, after: gridGutter * 4.25 },
        },
        [breakpoints.xl]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 3.5, after: gridGutter * 3.5 },
        },
        [breakpoints.lg]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 3, after: gridGutter * 3 },
        },
        [breakpoints.md]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 2, after: gridGutter * 2 },
        },
        [breakpoints.sm]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 2, after: gridGutter * 2 },
        },
        [480]: {
          perView: 1,
          gap: 0,
          peek: { before: gridGutter * 1.5, after: gridGutter * 1.5 },
        },
      },
    },
    { Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      // LazyLoad, 
      // CrossFade,
      // AutoHeight,
      Swipe }
      );
      
      return slider;
}