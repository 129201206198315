import { $all, on } from "@olmokit/dom";
import "./News.scss";
// import { $ } from "@acanto/core-dom";

/**
 * Component: CardNews
 */
export function CardNews() {
  console.log("CardNews mounted.");

  const cardImgs = $all(".CardNews:img");
  
  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }

}
