import "@olmokit/core/debug/api";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/typography/reset.scss";
import "@olmokit/core/icon/index.scss";
import "@olmokit/core/img";
import "components/Image";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { Selectlang } from "components/Selectlang";
import "components/Cta";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "utils/btn.scss";
import "utils/fonts.scss";
import "utils/logo.scss";
import "utils/acanto.scss";
import "./index.scss";

Header();
Footer();
Selectlang();

AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true
});