import { $all, on } from "@olmokit/dom";
import "components/Property";
import "./Product.scss";
// import { $ } from "@acanto/core-dom";

/**
 * Component: CardProduct
 */
export function CardProduct() {
  console.log("CardProduct mounted.");

  const cardImgs = $all(".CardProduct:img");
  const cardAcr = $all(".CardProduct:acronym");
  const cardProps = $all(".CardProduct:property");
  
  if (cardImgs || cardAcr || cardProps) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
    for(let i=0; i < cardAcr.length; i++){
      on(cardAcr[i], "click", () => {
        let dest = cardAcr[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
    for(let i=0; i < cardProps.length; i++){
      on(cardProps[i], "click", () => {
        let dest = cardProps[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }

}
