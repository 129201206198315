import "components/Breadcrumbs";
import "components/Property";
import "./index.scss";

/**
 * Component: Title
 */
export function Title() {
  console.log("Title mounted.");

  import("@olmokit/core/player").then(({ Player }) => {
    Player();
  });
  
}
