import { $, $all, listen, addClass,  } from "@olmokit/dom";
import "./index.scss";

/**
* Component: Selectlang
*
* The component will be initialised simply by calling the function
* `Selectlang();` when you need.
*/
export function Selectlang() {
  console.log("Selectlang mounted.");
  
  function selLang(id) {
    
    const selLang = $(".Selectlang:list-is-"+id);
    const drop = $(".Selectlang:dropbtn-is-"+id);
    
    if (selLang) {
      drop.addEventListener("click", () => {
        if (selLang.style.display === "none") {
          selLang.style.display = "block";
        } else {
          selLang.style.display = "none";
        }
      });
      
      // Close the dropdown menu if the user clicks outside of it
      window.onclick = function (event) {
        if (!event.target.matches(".drop")) {
          var dropdowns = document.getElementsByClassName("dropdown-content");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains("show")) {
              openDropdown.classList.remove("show");
            }
            if (openDropdown.style.display === "block") {
              openDropdown.style.display = "none";
            }
          }
        }
      };
    }
    
  }
  
  const sel = $all(".Selectlang:");
  
  if (sel) {
    for(let i=0; i < sel.length; i++){
      const id = sel[i].getAttribute('data-id');
      selLang(id);
    }
  }
    
  const $root = $(".LocaleSwitch:");
  
  listen("click", ".LocaleSwitch:item", (event) => {
    const $link = event.target;
    event.preventDefault();
    $root.classList.add("is-loading");
    location.href = $link.href;
  });
  
}
